.page-bedrijven ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.companies {
    padding-bottom: $gutter + 25px;
    margin-bottom: $gutter;
    background: url(images/slashbreak-white.png) no-repeat bottom right/auto 25px, #FFF url(images/slash.png) repeat-x bottom left/auto 25px;

    ul > li {
        border-top: 5px solid #000;
        padding-bottom: 5px;

        &.image {
            padding: $gutter 0;
            display: none;

            &.mobile {
                display: block;
            }

            > img {
                width: 100%;
            }

            @media (min-width: $min_large_tablet) {
                display: block;

                &.mobile {
                    display: none;
                }
            }
        }
    }

    ul ul > li {
        border: 0;
        padding: 0;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }

    h2 {
        @extend %dezheader;

        color: $blue;
        margin: 10px 0;
    }

    a {
        text-decoration: none;
        font-weight: normal;

        &:hover {
            text-decoration: underline;
        }
    }

    .location {
        @extend %dezheader;

        margin-left: $gutter;
    }

    @media (min-width: $min_large_tablet) {
        display: flex;
        flex-flow: row;

        > li {
            flex: 1 0 0;
            max-width: (100% / 3);

            &:not(:first-child) {
                margin-left: $gutter / 2;
            }

            &:not(:last-child) {
                margin-right: $gutter / 2;
            }
        }
    }
}

.page-bedrijven h2 {
    @extend %dezheader;

    font-size: 40px;
    margin: 15px 0;

    > span {
        color: $blue;
    }
}

ul.maps {
    display: flex;
    flex-flow: row wrap;
    margin: ($gutter / -2);

    > li {
        padding: ($gutter / 2);
        box-sizing: border-box;

        @media (min-width: $min_tablet) {
            flex: 1 0 50%;
            max-width: 50%;
        }
    }

    a {
        background: $grey;
        display: flex;

        &:hover {
            background: $blue;
        }
    }
}
