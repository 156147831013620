.teaser-cols .tease-room {
    border: none;
    padding: 0;

    > a {
        background: $green;
        color: #FFF;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        text-decoration: none;

        &:hover {
            background-color: #000;
        }
    }

    .thumbcontainer {
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex: 0 0 25%;
        align-self: start;

        &:after {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            box-sizing: border-box;
            border: $gutter solid transparent;
            border-width: ($gutter - 5px) $gutter $gutter;
        }

        img.thumb {
            align-self: start;
        }
    }

    .h2 {
        font-size: 20px;
        margin: 0;
        padding: 5px $gutter;
    }

    @media (min-width: $min_tablet) {
        > a {
            border: 1px solid #000;
            border-width: 5px 0 1px;
            background: #FFF;
            color: $green;
            flex-flow: column nowrap;
            background-color: transparent;
            align-items: start;

            &:hover {
                color: #000;
                background-color: transparent;
                border-top-color: $green;

                .thumbcontainer:after {
                    border-color: $green;
                }
            }
        }

        .thumbcontainer {
            flex: 0 0 auto;
            margin-right: 0;
        }

        .h2 {
            padding: $gutter 0;
        }
    }
}

.widget.testimonials {
    .sidebar-block-contents {
        padding-bottom: 0;

        &:after {
            display: none;
        }
    }

    .tease-testimonial {
        padding: $gutter;
        background: scale-color(mix($green, $grey), $lightness: 80%);
        border-bottom: $gutter solid #000;

        .author {
            @extend %dezheader;
            margin: 0;
        }

        h3 {
            font-weight: 300;
            text-transform: uppercase;
            margin: 0;
        }

        .quote {
            @extend %dezheader;

            font-size: 20px;
            border-top: 1px solid #000;
            color: $green;
            margin: $gutter 0;
        }

        .body {
            display: none;
            font-weight: 300;
        }
    }
}






.side-pics {
    margin: 0 $gutter 0 0;
    padding: 0;
    list-style-type: none;
    float: left;
    width: 100%;
    

    > li {
        padding: 5px 0;

        &:not(:last-child) {
            border-bottom: 2px dashed #000;
        }
    }

    @media (min-width: $min_tablet) {
        display: block;
    }
    @media (min-width: $min_medium) {
        width: 250px;
    }
    @media (min-width: $min_desktop) {
        width: 300px;
    }
}
body.room-template-default {
    .article-content { 
        display: flex; 
        flex-flow: column;
        @media (min-width: $min_medium) {
            display: block;
        }
        .left { 
            order: 2;
        }
    }


    #cboxLoadedContent { overflow: hidden !important; }
    .slick-track { 
        height: auto;
        width: 100%;
        @media (min-width: $min_desktop) {
            height: 200px;
        }
        //width: 100%;
    
    }
    .side-pic-container > div > img { width: 100%;}
    .slick-slide > div { height: 100%; }
    .slick-slide .slide { 
        height: 100%;
        img { 
            height: 100%;
        }
        @media (max-width: $min_medium) {
            height: auto;
            width: 100%;
            img {
                height: auto;
                width: 100%;
            }
        } 
    }
}

.side-pic-container {
    position: relative;
    display: block;
    font-size: 0;


    .slide.threesixty {
        cursor: pointer;
        display: block;
        span.fa { 
        font-size: 40px; 
        color: #fff; 
        position: absolute; 
        left: 50%; 
        margin-left: -20px;
        top: 50%; 
        margin-top: -20px;
        z-index: 10;}
    }
    .slick-prev {
        left: 5px;
        z-index: 10;
    }
    .slick-next {
        right: 5px;
        z-index: 10;
    }
    
    &:hover {
        text-decoration: none;
    }

    > img {
        display: block;
        width: 100%;
    }

    .side-pic-text {
        color: #FFF;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 8px $gutter 5px;
        background: rgba($green, 0.8);
        font-size: 20px;
        line-height: 1;
    }

    &.side-pics-top {
        padding-bottom: $gutter;
        border-bottom: 2px dashed #000;

        .side-pic-text {
            bottom: $gutter;
        }
    }
}

.side-pics-top + .article-grid .article-h1 {
    margin-top: $gutter;
}

.postquote {
    padding-left: 20px;
    font-size: 30px;
    font-style: italic;
    font-family: "Barlow Condensed";
    margin-bottom: $gutter;
}
