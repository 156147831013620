/*!
 * Theme Name: Z57
 * Description: Zuid57 Timber theme
*/

@import '_vars';
@import 'normalize.css/normalize';
@import 'font-awesome/scss/font-awesome';
@import 'jquery-colorbox/example3/colorbox';
@import 'slick-carousel/slick/slick.scss';
@import 'slick-carousel/slick/slick-theme.scss';

@import 'fonts';

html,
body {
    height: 100%;
}

body {
    background: #eee;
    min-width: 320px;
    font-family: MuseoSans, Sans;
    font-size: 14px;
    line-height: 1.25;
}

img {
    max-width: 100%;
}

a {
    color: inherit;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
    }
}

p {
    margin: $gutter 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

hr {
    width: 100%;
    color: inherit;
    border-style: solid;
    box-sizing: border-box;
}

%dezheader {
    font-family: DezBoulder;
    text-transform: uppercase;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
}

body.colorboxing {
    overflow: hidden;
}

#pagewrap {
    width:100%;
    box-sizing: border-box;
    background: #fff;
    margin: 0 auto;
    // Header size + topbar + gutter
    padding: (75px + 20px + $gutter) $gutter 0;
    box-shadow: 0 0 2px 2px #bbb;
    min-height: 100%;
    display: flex;
    flex-flow: column;

    @media (min-width: $min_tablet) {
        // Header size + topbar + gutter
        padding: (130px + 20px + $gutter) $gutter 0;
    }

    @media (min-width: $min_large_tablet) {
        padding-top: 30px;
    }

    @media (min-width: $min_desktop) {
        width: 1150px;
    }
}

.screen-reader-text {
    display: none;
}

img.thumb {
    width: 100%;
}

#topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    z-index: 10;
    background: #000 url(images/topbar.png) no-repeat center center / contain;

    body.admin-bar & {
        top: 46px;

        @media (min-width: 768px) {
            top: 32px;
        }
    }

    @media (min-width: $min_large_tablet) {
        height: 30px;
        position: absolute;
    }
}

header.header {
    position: fixed;
    top: 20px;
    left: 15px;
    right: 15px;
    height: 75px;
    margin: 0 0 $gutter;
    background: #FFF;
    border-bottom: $gutter solid #000;
    z-index: 5;

    body.admin-bar & {
        top: 20px + 46px;

        @media (min-width: 768px) {
            top: 20px + 32px;
        }
    }

    .logo {
        display: block;
        margin-right: 35px;

        > img {
            max-height: 45px;
            margin-top: $gutter;
            margin-bottom: $gutter;
        }
    }

    @media (min-width: $min_tablet) {
        height: 130px;

        .logo > img {
            max-height: 100px;
        }
    }

    @media (min-width: $min_large_tablet) {
        position: relative;
        border-bottom: 0;
        top: unset;
        left: unset;
        right: unset;
        height: auto;
        padding-bottom: 30px;
        background: url(images/slashbreak-white.png) no-repeat bottom right/auto 25px, #FFF url(images/slash.png) repeat-x bottom left / auto 25px;

        body.admin-bar & {
            top: unset;
        }

        .logo {
            margin-right: 150px;

            > img {
                max-height: none;
            }
        }
    }
}

.breadcrumb-line {
    @extend %dezheader;

    display: none;
    font-size: 30px;
    margin-bottom: $gutter;

    a {
        color: #000;
        text-decoration: none;
    }

    @media (min-width: $min_large_tablet) {
        display: block;
    }
}

.page-template-nieuws .breadcrumb-line {
    display: block;
}

@each $color in 'blue' 'purple' 'green' 'red' {
    body.pagecolor-#{$color} .breadcrumb_last:not(:first-child) {
        color: map-get($colors, #{$color});
    }
}

.content-top,
.article-content {
    margin-bottom: $gutter;
}

.content-wrapper {
    flex: 1 0 auto;
    display: flex;
    flex-flow: column;

    > .wrapper,
    > article {
        flex: 1 1 auto;
        min-height: 0;
        min-width: 0;

        > p,
        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6 {
            &:first-child:not(.breadcrumb-line) {
                margin-top: 0;
            }
        }
    }

    > aside {
        margin: 0 0 $gutter;
    }

    .layout-sidebar_left {
        order: -1;
    }

    @media (min-width: $min_tablet) {
        flex-flow: row;

        > aside {
            flex: 0 0 250px;
        }

        .layout-sidebar_right {
            margin-left: $gutter;
        }

        .layout-sidebar_left {
            margin-right: $gutter;
        }
    }
}

#pagewrap > footer {
    padding: $gutter 0;
}

a.social-icon {
    background: rgba(#000, 0.75);
    text-decoration: none;
    color: #FFF;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    display: inline-block;
    font-size: 20px;

    > .fa {
        line-height: 29px;
    }

    > .fa-facebook-f {
        padding-right: 2px;
    }

    > .fa-instagram {
        padding-left: 1px;
    }
}

.readmore {
    font-size: 12px;
    font-weight: bold;
    font-family: MuseoSans;
    text-transform: lowercase;
    text-decoration: underline;

    &:before {
        display: inline-block;
        content: ">";
        margin-right: 2px;
    }
}

.pagination {
    list-style-type: none;
    margin: $gutter 0 0;
    padding: 0;
    display: flex;
    justify-content: center;

    > li a,
    > li button,
    > li.current span {
        display: block;
        text-transform: uppercase;
        font-family: MuseoSans;
        color: #000;
        margin: 5px;
        padding: 5px $gutter;
        border: 0;
        background: $grey;
        transition: background 0.5s;
        text-decoration: none;
    }

    > li {
        display: none;

        &.prev,
        &.next,
        &.current {
            display: block;
        }

        &.current .dots {
            display: none;
        }

        @media (min-width: $min_tablet) {
            &.first,
            &.last,
            &.current {
                display: block;
            }
        }

        @media (min-width: $min_desktop) {
            display: block;

            &.current .dots {
                display: block;
            }
        }

        a:hover {
            text-decoration: underline;
        }
    }

    > li button[disabled],
    > li.current span.dots {
        background: desaturate($grey, 100%);
        color: #FFF;
    }

    > li.current span.current {
        background: #000;
        color: #FFF;
    }
}

@import 'menu';
@import 'content';
@import 'sidebar';
@import 'home';
@import 'companies';
@import 'bezoekersinfo';
@import 'contact';
@import 'rooms';
@import 'workshops';
@import 'agenda';
