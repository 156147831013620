#nav-desktop {
    clear: both;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    @media (min-width: $min_large_tablet) {
        justify-content: space-between;
    }
}

.menu > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    a {
        display: block;
        width: 100%;
        text-decoration: none;
    }
}

.nav-main .menu {
    z-index: 10;

    a {
        height: auto;
        font-size: 18px;
        font-weight: bold;
    }

    .menu a {
        font-weight: normal;
    }
}

#nav-desktop > .menu {
    display: none;
    line-height: 25px;
    width: 100%;

    a {
        @extend %dezheader;
    }

    > ul {
        display: flex;
        flex-flow: row;
        padding: ($gutter / 2) 0 0;
        border-top: $gutter solid #000;

        > li {
            flex: 1 0 0;
            text-align: center;

            &:not(:last-child) {
                border-right: 1px solid #000;
            }

            &:not(:first-child) {
                padding-left: $gutter / 2;
            }

            &:not(:last-child) {
                padding-right: $gutter / 2;
            }

            > .hover {
                > a {
                    color: $red;
                    font-size: 22px;
                    font-weight: bold;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                > .menu {
                    position: relative;
                    margin-top: -5px;
                    margin-bottom: -100%;
                    width: 100%;
                    background: $red;
                    display: none;

                    > ul {
                        padding: 5px $gutter $gutter;

                        > li {
                            border-top: 2px solid #FFF;

                            &:last-child {
                                border-bottom: 2px solid #FFF;
                            }
                        }

                        li > .hover > a {
                            padding: 5px 0;
                            color: #FFF;
                        }

                        li > .hover > a:hover {
                            color: #000;
                        }
                    }
                }

                &:hover {
                    > .menu {
                        display: block;
                    }

                    > a {
                        color: #FFF;
                        background: $red;
                    }
                }
            }

            @each $color in 'blue' 'purple' 'green' 'red' {
                &.hover-#{$color} > .hover {
                    &:hover,
                    body.pagecolor-#{$color} & {
                        > a,
                        > .menu {
                            color: #FFF;
                            background: map-get($colors, #{$color});
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $min_large_tablet) {
        display: block;
    }
}

#nav-mobile {
    position: fixed;
    right: 0;
    top: 20px;
    padding: 10px;
    margin: 5px;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    z-index: 10;

    body.admin-bar & {
        top: 20px + 46px;

        @media (min-width: 768px) {
            top: 20px + 32px;
        }
    }

    .hamburger {
        text-decoration: none;
        font-size: 0;
        color: $red;
        float: right;
        width: 30px;
        text-align: center;

        .fa {
            font-size: 30px;
            display: block;
        }
    }

    > .menu {
        display: none;
        clear: both;
        padding-top: 10px;

        > ul > li {
            padding: 5px 0;
            border: 0 solid #000;
            border-bottom-width: 1px;

            &:first-child {
                border-top-width: 1px;
            }

            > .hover > a {
                @extend %dezheader;
            }
        }

        a {
            color: #FFF;
        }

        > ul > li > .hover > .menu {
            margin: 0 (-$gutter);
            height: auto;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s cubic-bezier(1, 0, 0, 1);

            > ul {
                padding: 0;
                background: transparent;
                margin-bottom: $gutter;

                > li {
                    border-bottom: 0;
                }
            }

            a {
                color: #FFF;
                padding: 0 $gutter 0 (2 * $gutter);
                transition: background 0.5s;

                &:hover {
                    background: rgba(darken($red, 25%), 0.95);
                }
            }

            .menu a {
                padding-left: 3 * $gutter;
            }
        }

        .hover > a.open + .menu {
            max-height: 300px;
        }
    }

    .search-form {
        display: none;
        position: relative;
        margin: $gutter 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .search-field {
        background: #FFF;
        color: darken($red, 25%);
        border: 0;
        padding: 6px 30px 8px 10px;
        font-size: 14px;
        line-height: 16px;
        width: 100%;
        box-sizing: border-box;
    }

    .search-submit {
        color: #FFF;
        background: #000;
        padding: 0 5px;
        border: 0;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;

        .fa {
            line-height: 25px;
            font-size: 20px;
            height: 100%;
        }
    }

    .layout-mobile_menu {
        display: none;
    }

    a.social-icon {
        background: #FFF;
        color: $red;
    }

    &.open {
        background: rgba(darken($red, 5%), 0.9);
        border-radius: 0;
        padding: 15px;
        margin: 0;
        width: auto;
        height: auto;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 100vh;

        > .menu,
        > .search-form,
        > .layout-mobile_menu {
            display: block;
        }

        .hamburger {
            color: #FFF;
        }
    }

    @media (min-width: $min_large_tablet) {
        position: static;
        width: auto;
        height: auto;
        padding: 0;
        margin: 0;

        body.admin-bar & {
            top: 90px;
        }

        &.open {
            overflow: visible;
            padding: 0;
            background: transparent;

            .menu {
                display: none;
            }

            .layout-mobile_menu {
                display: block;
            }
        }

        .hamburger,
        .menu {
            display: none;
        }

        .layout-mobile_menu {
            position: absolute;
            top: $gutter;
            right: 0;
            display: block;

            #custom_html-2 > .textwidget {
                display: flex;
                flex-flow: column nowrap;
            }

            a.social-icon {
                background: $red;
                color: #FFF;
                margin-bottom: 5px;
            }
        }

        .search-form {
            position: absolute;
            bottom: 123px;
            right: 0;
            display: block;
            margin: 0;
        }

        .search-field {
            background: lighten($grey, 10%);
            color: darken($grey, 40%);
            width: 250px;
        }
    }
}

#mobile-menu {
    margin: 0 0 $gutter;
    padding: 0;
    list-style-type: none;
    border-bottom: ($gutter - 1px) solid #000;

    li {
        border: 1px solid #000;
        border-width: 1px 0 0;

        &:last-child {
            border-bottom-width: 1px;
        }

        a {
            @extend %dezheader;
            padding: $gutter 0;
            display: block;
            margin: 5px 0;
            text-decoration: none;
            color: red;
            font-size: 20px;
            text-align: center;

            &:hover {
                background: $red;
                color: #FFF;
            }
        }

        @each $color in 'blue' 'purple' 'green' 'red' {
            &.hover-#{$color} {
                > a:hover,
                body.pagecolor-#{$color} & > a {
                    color: #FFF;
                    background: map-get($colors, #{$color});
                }
            }
        }
    }

    @media (min-width: $min_large_tablet) {
        display: none;
    }
}

#mobile-menu-subs {
    margin: (-$gutter) 0 $gutter;
    padding: 0;
    list-style-type: none;

    @media (min-width: $min_large_tablet) {
        display: none;
    }
}

#mobile-menu-subs > li {
    display: none;

    > ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    a {
        @extend %dezheader;

        display: flex;
        text-align: left;
        font-size: 20px;
        text-decoration: none;
        padding: $gutter 0;
        border-bottom: 5px solid #000;

        &:before {
            display: inline-block;
            content: "»";
            margin-right: 1em;
        }
    }

    @each $color in 'green' 'red' {
        body.pagecolor-#{$color} &.hover-#{$color} {
            display: block;
        }

        &.hover-#{$color} li.current-menu-item a,
        &.hover-#{$color} a:hover {
            color: map-get($colors, #{$color});
        }
    }
}
