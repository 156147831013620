.overz57 {
    display: none;
    padding: $gutter;
    margin-bottom: $gutter;
    color: #FFF;
    flex-flow: row nowrap;
    background: transparent no-repeat center / cover;

    > .content {
        flex: 1 1 auto;
        margin-right: $gutter;
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        h2 {
            @extend %dezheader;

            margin-top: 0;
        }

        a {
            font-weight: bold;
        }
    }

    > img {
        flex: 0 0 auto;
        align-self: start;
    }

    @media (min-width: $min_desktop) {
        display: flex;
    }
}

.bigpic {
    display: flex;
    flex-flow: column nowrap;
    margin: $gutter 0 0;
    border: 0 solid #000;

    @media (min-width: $min_large_tablet) {
        margin: 0 0 $gutter;
        border-width: 0 0 1px;
    }

    @media (min-width: $min_desktop) {
        padding: $gutter 0 0;
        border-width: 1px 0;
    }

    > .text {
        font-size: 16px;
        padding: 0 0 ($gutter / 2);
        font-style: italic;

        @media (min-width: $min_tablet) {
            font-size: 20px;
        }

        &:before {
            @extend %dezheader;

            display: inline-block;
            content: "»";
            margin-right: 0.5em;
            font-size: 2em;
            vertical-align: sub;
        }
    }
}
