.bezoekersinfo {
    display: flex;
    flex-flow: row wrap;
    margin: ($gutter / -2);
    margin-bottom: ($gutter / 2);

    > div {
        box-sizing: border-box;
        padding: ($gutter / 2);
        flex: 1 0 290px;

        &.wideimg {
            flex: 2 1 500px;
        }

        @media (min-width: $min_large_tablet) {
            flex: 1 0 (100% / 3);

            &.wideimg {
                flex: 2 0 (100% / 3 * 2);
            }
        }

        > div {
            border-top: 5px solid #000;
            padding: $gutter 0;
        }

        &.image > div,
        &.wideimg > div {
            padding-bottom: 0;
            font-size: 0;
        }

        &.image img,
        &.wideimg img {
            width: 100%;
            width: 100%;
        }

        h2 {
            @extend %dezheader;

            color: $red;
            margin: 0 0 $gutter;
            border-bottom: 1px solid #000;
            padding-bottom: 15px;
        }
    }

    .ovinfo ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        > li > a {
            @extend %dezheader;

            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            &:before {
                @include fa-icon();

                content: $fa-var-external-link;
                margin-right: 1em;
            }
        }
    }
}
