
/**
 * Default SCSS style template.
 */

.page-template-agenda {
    .breadcrumb-line { display: block;}
    article.tease-event {
        display: flex;
        flex-flow: row wrap;
        font-family: 'DezBoulder';
        font-size: 30px;
        color: #000;
        border-bottom: 1px dashed black;
        padding-bottom: $gutter;
        margin-bottom: $gutter;
        .start_date {             
            margin-right: $gutter;
            color: $red;
            white-space: nowrap;
            
        }
    }

}