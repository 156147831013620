h1.article-h1 {
    @extend %dezheader;

    font-size: 40px;
    margin: 0;

    > span {
        color: $red;

        body.pagecolor-green & {
            color: $green;
        }

        body.pagecolor-purple & {
            color: $purple;
        }

        body.pagecolor-blue & {
            color: $blue;
        }
    }

    @media (min-width: $min_desktop) {
        font-size: 65px;
    }
}

.article-thumbnail {
    width: 100%;
    display: block;
}

.teaser-cols {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: -($gutter / 2);
    margin-bottom: -($gutter / 2) + $gutter;

    .tease {
        border-top: 5px solid #000;
        border-bottom: 1px solid #000;
        padding-bottom: $gutter;
        width: 100%;
    }

    > div {
        flex: 1 0 100%;
        padding: ($gutter / 2);
        box-sizing: border-box;
        max-width: 100%;
        display: flex;

        @media (min-width: $min_tablet) {
            flex: 1 0 50%;
            max-width: 50%;
        }

        @media (min-width: $min_large_tablet) {
            flex: 1 0 (100% / 3);
            max-width: (100% / 3);
        }
    }

    h2 {
        @extend %dezheader;

        font-size: 30px;
        margin: $gutter 0;

        > a {
            color: #000;
        }
    }

    &.teaser-2-cols > div {
        @media (min-width: $min_tablet) {
            flex: 1 0 100%;
            max-width: 100%;
        }

        @media (min-width: $min_large_tablet) {
            flex: 1 0 50%;
            max-width: 50%;
        }
    }
}

.content-wrapper > .post-type-page {
    width: 100%;
}

.linkblock a {
    @extend %dezheader;

    border: 1px solid #000;
    border-width: 1px 0;
    display: block;
    padding: 15px 0;
    font-size: 1.5em;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &:before {
        content: ">";
        display: inline-block;
    }
}

.text-cols {
    columns: 290px 2;
}

.block-news,
#wpcf7-f118-o1 form.wpcf7-form {
    padding: $gutter;
    background: $grey;
    margin-bottom: $gutter;

    > h1 {
        @extend %dezheader;
        background: url(images/slashbreak-grey.png) no-repeat bottom right/auto 10px, url(images/slash.png) repeat-x bottom left / auto 10px, url(images/slashbreak-grey.png) no-repeat top right/auto 10px, $grey url(images/slash.png) repeat-x top left / auto 10px;
        padding: $gutter 0;
        margin: 0 0 $gutter;
        text-align: center;
        font-size: 19px;
        white-space: nowrap;

        .red {
            color: $red;
        }

        .green {
            color: $green;
        }

        @media (min-width: $min_large_tablet) {
            padding: ($gutter * 2) 0;
            font-size: 42px;
            background-size: auto 25px, auto 25px, auto 25px, auto 25px;
        }

        @media (min-width: $min_desktop) {
            padding: ($gutter * 2) 0;
            font-size: 62px;
        }
    }

    .news {
        background: url(images/slashbreak-grey.png) no-repeat bottom right/auto 10px, $grey url(images/slash.png) repeat-x bottom left / auto 10px;
        padding-bottom: $gutter * 2;

        @media (min-width: $min_large_tablet) {
            background-size: auto 25px, auto 25px;
            padding-bottom: $gutter * 3;
        }
    }

    .tease {
        padding: $gutter 0;
        border: 1px solid #000;
        border-width: 1px 0 0;

        &:last-child {
            border-width: 1px 0;
        }

        h2 {
            font-weight: 300;
            margin-top: 0;
        }

        .body {
            display: none;
        }

        @media (min-width: $min_desktop) {
            display: flex;
            flex-flow: row;

            &.open {
                // flex-flow: column;
            }

            .img-container {
                flex: 0 0 300px;
                margin-right: $gutter;
            }
        }

        &.open {
            .readmore,
            .preview {
                display: none;
            }

            .body {
                display: block;
            }
        }
    }
}
