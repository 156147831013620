#wpcf7-f118-o1 form.wpcf7-form {
    display: flex;
    flex-flow: column;

    br {
        display: none;
    }

    .wpcf7-response-output {
        margin: $gutter 0;
        border: 0;
        background: #FFF;
        padding: $gutter;

        &.wpcf7-validation-errors {
            color: $red;
            border: 1px solid scale-color($red, $lightness: 80%);
            background: scale-color($red, $lightness: 95%);
        }
    }

    .wpcf7-form-control-wrap {
        display: block;
        margin-bottom: $gutter;
    }

    .wpcf7-form-control-wrap > input {
        width: 100%;
        border: 0;
        border-radius: 5px;
        padding: 0 $gutter;
        box-sizing: border-box;
        height: 40px;
    }

    .checkboxes {
        display: flex;
        flex-flow: row nowrap;
        margin: 0 ($gutter / -2);

        > .wpcf7-form-control-wrap {
            flex: 1 0 50%;
            padding: ($gutter / 2);
            box-sizing: border-box;

            > span {
                border-top: 5px solid #000;
                padding-top: $gutter;
                display: block;
            }

            .wpcf7-list-item {
                margin-left: 0;
            }
        }

        .wpcf7-list-item-label:before {
            @include fa-icon();

            content: "";
            border: 1px solid #000;
            background: transparent;
            vertical-align: bottom;
            border-radius: 0.5em;
            height: 1em;
            width: 1em;
            margin-right: 1em;
        }

        input[type="checkbox"] {
            display: none;

            &:checked + .wpcf7-list-item-label:before {
                content: $fa-var-check;
            }
        }
    }

    .wpcf7-submit {
        border: 0;
        background: #FFF;
        border-radius: 5px;
        padding: 5px 15px;
    }
}

.widget.hiring {
    .sidebar-block-contents-green ul {
        padding: 0;
        margin: 0 0 $gutter;
        list-style-type: none;

        li {
            font-weight: bold;
            border: 1px solid #000;
            border-width: 1px 0 0;
            padding: $gutter 0;

            &:last-child {
                border-bottom-width: 1px;
            }
        }
    }

    p {
        margin: 0;
    }

    .address {
        font-weight: bold;
        margin-top: 15px;
    }

    .block {
        border: 1px solid #000;
        border-width: 1px 0;
        margin: $gutter 0;
        padding: $gutter 0;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
