.widget.workshops {
    margin-top: -$gutter;
    position: relative;

    .maincats > div {
        font-size: 20px;
        border-bottom: 5px solid #000;
        padding: ($gutter / 2) 0;

        &.cat {
            @extend %dezheader;
        }

        &.openFilters {
            font-weight: 300;
            text-transform: uppercase;

            &:before {
                content: ">";
                display: inline-block;
                width: 1em;
                margin-right: 0.5em;
            }

            @media (min-width: $min_tablet) {
                display: none;
            }
        }
    }

    > .filters {
        display: none;
    }
}

#cboxLoadedContent,
.widget.workshops {
    input[type="checkbox"] {
        display: none;

        + span:before {
            display: inline-block;
            content: "";
            box-sizing: border-box;
            border: 2px solid #000;
            background: transparent;
            vertical-align: bottom;
            border-radius: 0.5em;
            height: 1em;
            width: 1em;
            margin-right: 0.5em;
        }

        &:checked + span {
            color: $purple;

            &:before {
                border-color: $purple;
                background-color: $purple;
            }
        }
    }

    > .filters {
        padding: 0 $gutter $gutter;
        background: rgba($purple, 0.9);
        color: #FFF;

        h2 {
            text-indent: -9999px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 300;
            margin: 0;
        }

        label {
            display: block;
        }

        input[type="checkbox"] + span:before {
            border-color: #FFF;
        }

        input[type="checkbox"]:checked + span {
            color: #FFF;
            font-weight: bold;

            &:before {
                background-color: #FFF;
            }
        }

        .close {
            font-size: 24px;
            line-height: 0;
            position: absolute;
            top: $gutter / 2;
            right: ($gutter / 2) + 2px;

            &:before {
                @include fa-icon();

                content: $fa-var-times;
            }
        }

        .search {
            width: 100%;
            background: scale-color($purple, $lightness: -20%);
            margin: $gutter (-$gutter) (-$gutter);
            padding: $gutter;
            text-transform: uppercase;
            text-align: center;
        }
    }
}

@media (min-width: $min_tablet) {
    .widget.workshops .filters {
        display: block;
        padding: 0;
        background: transparent;
        position: static;
        color: #000;

        h2 {
            text-indent: 0;
            margin: $gutter 0 0;
        }

        input[type="checkbox"] + span:before {
            border-color: #000;
        }

        input[type="checkbox"]:checked + span {
            color: $purple;

            &:before {
                border-color: $purple;
                background-color: $purple;
            }
        }

        .close,
        .search {
            display: none;
        }
    }
}

body.post-type-archive-workshop .fluff > img {
    border-top: 5px solid #000;
    width: 100%;
}

.tease-workshop {
    display: flex;
    flex-flow: column nowrap;
    padding: $gutter 0;

    h2 {
        color: $purple;
        font-size: 16px;
        margin: 0;
    }

    .level {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 16px;
    }

    .body {
        flex: 1 0 auto;
        margin: $gutter 0;
    }

    a.workshop-link {
        @extend %dezheader;

        display: block;
        text-decoration: none;
        font-size: 16px;

        &:hover {
            text-decoration: underline;
        }
    }
}
