// DezBoulder
@font-face {
    font-family: 'DezBoulder';
    src: url('./fonts/DezBoulder.otf');
    font-weight: normal;
    font-style: normal;
}

// MuseoSans
@font-face {
    font-family: 'MuseoSans';
    src: url('./fonts/MuseoSans-100.otf');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('./fonts/MuseoSans-300.otf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('./fonts/MuseoSans-300Italic.otf');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('./fonts/MuseoSans-500.otf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('./fonts/MuseoSans-500Italic.otf');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('./fonts/MuseoSans-700.otf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('./fonts/MuseoSans-900.otf');
    font-weight: 900;
    font-style: normal;
}
