$gutter: 15px;
$min_tablet: 600px;
$min_large_tablet: 900px;
$min_medium: 800px;
$min_desktop: 1200px;
$max_mobile: $min_tablet - 1px;
$max_tablet: $min_large_tablet - 1px;
$max_large_tablet: $min_desktop - 1px;

$red: #FE0000;
$blue: #007ba6;
$purple: #8b308f;
$green: #4ea16d;
$grey: #e2d5cf;
$colors: (
    'red': $red,
    'blue': $blue,
    'purple': $purple,
    'green': $green,
    'grey': $grey,
);

$fa-font-path: './build/fonts';
$slick-font-path: './build/fonts/';
$slick-loader-path: './build/';

