.widget hr {
    border-width: $gutter 0 0;
    margin: $gutter 0;
}

.sidebar-block {
    display: flex;
    flex-flow: column nowrap;

    > img.header {
        width: 100%;
    }
}

.sidebar-block-contents {
    padding: 5%;

    &:after {
        padding-bottom: 25px;
        background: no-repeat bottom right/auto 25px, repeat-x bottom left / auto 25px;
        background-color: inherit;
        display: block;
        content: "";

        @media (min-width: $min_tablet) {
            padding-bottom: 12px;
            background-size: auto 12px, auto 12px,
        }
    }

    h2 {
        @extend %dezheader;

        text-align: center;
        margin: 0 0 $gutter;
        font-size: 30px;
        padding: ($gutter + 25px) 0;
        background: no-repeat bottom right/auto 25px, repeat-x bottom left / auto 25px, no-repeat top right/auto 25px, repeat-x top left / auto 25px;

        > span {
            color: #FFF;
        }

        @media (min-width: $min_tablet) {
            padding: ($gutter + 12px) 0;
            background-size: auto 12px, auto 12px, auto 12px, auto 12px,
        }
    }
}

.sidebar-block-contents-red {
    background-color: $red;

    &:after, {
        background-image: url(images/slashbreak-red.png), url(images/slash-white.png);
    }

    h2 {
        background-image: url(images/slashbreak-red.png), url(images/slash-white.png), url(images/slashbreak-red.png), url(images/slash-white.png);
    }
}

.sidebar-block-contents-green {
    background-color: $green;

    &:after {
        background-image: url(images/slashbreak-green.png), url(images/slash-white.png);
    }

    h2 {
        background-image: url(images/slashbreak-green.png), url(images/slash-white.png), url(images/slashbreak-green.png), url(images/slash-white.png);
    }
}

.widget.coming_up .sidebar-block-contents {
    color: #FFF;
    margin-left: 4%;

    .tease {
        border: 1px solid #000;
        border-width: 1px 0 0;
        font-size: 16px;
        padding: 7.5px 0;

        &:last-child {
            border-bottom-width: 1px;
        }
    }

    .tease > a {
        display: block;
        text-decoration: none;
        font-weight: 300;
        padding: $gutter 0;

        @media (min-width: $min_tablet) {
            padding: ($gutter / 2) 0;
        }

        &:hover {
            color: #000;
        }
    }

    .start_date {
        @extend %dezheader;
        font-size: 1.5em;
    }

    &:after {
        padding-bottom: 12%;
        background-size: 2% auto, 2.625% auto;
    }
}

.widget.cafe {
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li {
        color: #FFF;
        border-bottom: 1px solid #000;
        padding-bottom: $gutter;
        margin-bottom: $gutter;
    }

    strong {
        font-weight: 900;
    }

    p {
        margin: 0;
    }
}
